import React, { useState, createRef, useEffect } from "react";
import {
  Grid,
  Backdrop,
  CircularProgress,
  Typography,
  Modal,
} from "@mui/material";
import styles from "./index.module.css";
import copy from "constants/copy";
import { useParams } from "react-router-dom";
import StepperMessage from "components/stepperMessage";
import { Input, isFormValid } from "components/controls";
import { FilledButton } from "components/buttons";
import { useSelector } from "react-redux";
import VerifyAddress from "components/verifyAddress";
import states from "constants/states";
import Shell from "components/shell";
import { shippingAPI } from "data";
import { formValid } from "utilities";
import { ErrorModal } from "components/errorModal";

export const Shipping = (props) => {
  const [subject, setSubject] = useState(useParams().subject);
  const [loading, updateLoading] = useState(false);
  const [shippingnValues, updateShippingnValues] = useState({});
  const [submitable, updateSubmitable] = useState(false);
  const [suggestedData, setSuggestedData] = useState(null);
  const [enteredData, setEnteredData] = useState(null);
  const user = useSelector((state) => state.user);
  const dataList = [
    "shippName",
    "shippingLastName",
    "primaryAddress",
    "shippingCity",
    "shippingState",
    "shippingPostalCode",
  ];
  const [open, setOpen] = useState({
    open: false,
    // target: null,
    // message: null,
    code: null,
  });
  const handleClose = () => {
    setOpen({ open: false });
  };
  const handleOpen = (data = [], message = "", code = "") =>
    setOpen({ open: true, target: data, message: message, code: code });

  const [openErrorModal, setOpenErrorModal] = useState({
    open: false,
    // target: null,
    // message: null,
    code: null,
  });
  const handleErrorModalClose = () => {
    setOpenErrorModal({ open: false });
  };
  const handleErrorModalOpen = (data = [], message = "", code = "") =>
    setOpenErrorModal({
      open: true,
      target: data,
      message: message,
      code: code,
    });

  const formRef = createRef();

  const handleRegistrationChange = (e) => {
    const target = e.target;
    let newValue = target.value;
    const name = target.name;
    let shippingData = shippingnValues;
    shippingData = { ...shippingnValues, [name]: newValue };
    updateShippingnValues(shippingData);
    updateSubmitable(formValid(shippingData, dataList));
    // updateSubmitable(isFormValid(formRef.current));
  };

  const fieldConfigs = {
    firstName: {
      type: "text",
      label: `First Name`,
      value:
        shippingnValues.shippName !== undefined
          ? shippingnValues.shippName
          : user?.data?.first_name,
      name: "shippName",
      onChange: handleRegistrationChange,
      validation: ["string"],
    },
    lastName: {
      type: "text",
      label: `Last Name`,
      value:
        shippingnValues.shippingLastName !== undefined
          ? shippingnValues.shippingLastName
          : user?.data?.last_name,
      name: "shippingLastName",
      onChange: handleRegistrationChange,
      validation: ["string"],
    },
    primaryAddress: {
      type: "text",
      label: `Address Line 1`,
      value: shippingnValues.primaryAddress,
      name: "primaryAddress",
      onChange: handleRegistrationChange,
      validation: ["string"],
    },
    secondaryAddress: {
      type: "text",
      label: `Address Line 2`,
      value: shippingnValues.secondaryAddress,
      name: "secondaryAddress",
      onChange: handleRegistrationChange,
      // validation: [],
    },
    city: {
      type: "text",
      label: `City`,
      value: shippingnValues.shippingCity,
      name: "shippingCity",
      onChange: handleRegistrationChange,
      validation: ["string"],
    },
    state: {
      type: "select",
      label: "State",
      value: shippingnValues.shippingState,
      name: "shippingState",
      onChange: handleRegistrationChange,
      validation: ["selected"],
      defaultOptionName: shippingnValues?.shippingState,
      options: states.sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      ),
      placeholder: "",
    },
    postalCode: {
      type: "text",
      label: `Postal Code`,
      value: shippingnValues.shippingPostalCode,
      name: "shippingPostalCode",
      onChange: handleRegistrationChange,
      validation: ["string"],
    },
  };

  useEffect(() => {
    updateShippingnValues({
      ...shippingnValues,
      shippName:
        shippingnValues.shippName !== undefined
          ? shippingnValues.shippName
          : user?.data?.first_name,
      shippingLastName:
        shippingnValues.shippingLastName !== undefined
          ? shippingnValues.shippingLastName
          : user?.data?.last_name,
    });
  }, []);
  const messages = {
    title: copy(`enrollment.shippingTitle.${subject}`, [
      "BOLD Capital Partners",
    ]),
    subtitle: copy(`enrollment.subShippingTitle.${subject}`),
    allFieldsRequired: copy(`enrollment.form.allFieldsRequired.${subject}`),
    shippingFormTitle: copy(`enrollment.form.shippingFormTitle.${subject}`),
  };

  const onSubmit = async () => {
    const body = {
      name: `${shippingnValues?.shippName} ${shippingnValues?.shippingLastName}`,
      primaryLine: shippingnValues?.primaryAddress,
      secondaryLine: shippingnValues?.secondaryAddress,
      city: shippingnValues?.shippingCity,
      state: shippingnValues?.shippingState,
      zipCode: shippingnValues?.shippingPostalCode,
      verification: "ok",
    };
    setEnteredData(body);
    try {
      updateLoading(true);
      let resData = await shippingAPI(body, user?.data);
      let res = await resData.json();
      setSuggestedData(res);
    } catch (err) {
      console.log(err);
    }
    updateLoading(false);
    handleOpen();
  };
  return (
    <Shell>
      <Grid container className={styles.container}>
        <Grid item xs={0} md={0} lg={12}></Grid>
        <Grid item xs={12} md={12} lg={8} className={styles.titles}>
          {/* activeSteps here refers to array index of Stepper 
            this will point to index of current step 
          */}
          <StepperMessage
            title={messages.title}
            subtitle={messages.subtitle}
            activeStep={1}
          />
        </Grid>
        <Grid item xs={0} md={0} lg={12}></Grid>
      </Grid>
      <Grid
        sm={6}
        md={6}
        lg={6}
        container
        order={{ xs: 3, md: 2 }}
        className={styles.backdropContainer}
      >
        <Backdrop open={loading} className={styles.backdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Grid>
      <form
        // onSubmit={handleSubmit}
        className={styles["registration-form"]}
        ref={formRef}
      >
        <Typography variant="h3" className={styles["registration-headline"]}>
          {messages.shippingFormTitle}{" "}
          <span className={styles["all-fields-required"]}>
            {messages.allFieldsRequired}
          </span>
        </Typography>
        <Typography variant="h6" className={styles["registration-subHeading"]}>
          Please enter your information as it appears on your insurance card.
        </Typography>
        <Grid container spacing={1} className={styles["form-fields"]}>
          <Grid item xs={6} className={styles["form-row"]}>
            <Input {...fieldConfigs.firstName} />
          </Grid>
          <Grid item xs={6} className={styles["form-row"]}>
            <Input {...fieldConfigs.lastName} />
          </Grid>
          <Grid item xs={12} className={styles["form-row"]}>
            <Input {...fieldConfigs.primaryAddress} />
          </Grid>
          <Grid item xs={12} className={styles["form-row"]}>
            <Input {...fieldConfigs.secondaryAddress} />
          </Grid>
          <Grid item xs={12} className={styles["form-row"]}>
            <Input {...fieldConfigs.city} />
          </Grid>
          <Grid item xs={6} className={styles["form-row"]}>
            <Input {...fieldConfigs.state} />
          </Grid>
          <Grid item xs={6} className={styles["form-row"]}>
            <Input {...fieldConfigs.postalCode} />
          </Grid>
        </Grid>
        <div style={{ margin: "2rem auto auto auto" }}>
          {" "}
          <FilledButton
            sponsor={useParams().sponsor}
            text={`Done`}
            disabled={!submitable}
            width={"100%"}
            noMargin={true}
            submit={onSubmit}
          />
        </div>
      </form>
      <div>
        {/* <Button onClick={handleOpen}>Open modal</Button> */}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open.open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <VerifyAddress
            handleClose={handleClose}
            open={open.open}
            code={open.code}
            suggestedData={suggestedData}
            enteredData={enteredData}
            handleNavigate={props.handleNavigate}
            shippingnValues={shippingnValues}
            updateShippingnValues={updateShippingnValues}
            handleErrorModalOpen={handleErrorModalOpen}
          />
        </Modal>
      </div>
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={openErrorModal.open}
          onClose={handleErrorModalClose}
          closeAfterTransition
          // BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <ErrorModal
            handleClose={handleErrorModalClose}
            open={openErrorModal.open}
            code={openErrorModal.code}
            text={"Something went wrong."}
            subText={
              <>
                <span>Please contact </span>
                <a href="mailto:support@alivecor.com">support@alivecor.com.</a>
              </>
            }
          />
        </Modal>
      </div>
    </Shell>
  );
};

export default Shipping;
