import { useEffect } from "react";
import Shell from "components/shell";
import { Grid, Typography } from "@mui/material";
import Success from "../../assets/img/Success.png";
import { OutlinedButton } from "components/buttons";
import { useParams } from "react-router-dom";
import {
  SUBJECT_EMPLOYEE,
  SUBJECT_DEPENDENT,
  SUBJECT_PROVIDER,
} from "../../constants/app";
import classes from "./index.module.css";
import StepperMessage from "components/stepperMessage";
import ConfirmationAndQR from "./ConfirmationAndQR";

const Confirmation = () => {
  const fetchUserName = () => {
    const name = JSON.parse(localStorage.getItem("_data")).firstName || "User";
    return name;
  };
  // const containerStyles = containerUseStyles();
  const { sponsor, code, subject } = useParams();
  const addDependentLink = `/enroll/${sponsor}/provider`;
  const enrollMyselfLink = `/enroll/${sponsor}/${SUBJECT_EMPLOYEE}`;

  useEffect(() => window.scrollTo(0, 0));


  return (
    <Shell confirmation={true}>
      <Grid container className={classes.container}>
        <Grid item xs={0} md={0} lg={12}></Grid>
        <Grid item xs={12} md={12} lg={8} className={classes.titles}>
          {/* activeSteps here refers to array index of Stepper 
            this will point to index of current step 
          */}
          <StepperMessage activeStep={2} />
        </Grid>
        <Grid item xs={0} md={0} lg={12}></Grid>
      </Grid>
      {((subject === SUBJECT_EMPLOYEE || subject === SUBJECT_DEPENDENT) && (
        <ConfirmationAndQR />
      )) ||
        (subject === SUBJECT_PROVIDER && (
          <Grid container className={classes.confirmation}>
            <Grid item md={6} className={classes["completion-message"]}>
              <img src={Success} />
              <Typography variant="h1" className={classes.enrollment_header}>
                Dependent Added
              </Typography>
              <Typography
                variant="subtitle1"
                className={classes["success-message"]}
              >
                Your dependent will receive an invitation email from
                support@alivecor.com to begin their enrollment.
              </Typography>
              <div className={classes["add-dependent-container"]}>
                <OutlinedButton
                  link={enrollMyselfLink}
                  text="enroll myself"
                  size={"24vw"}
                  variant="contained"
                />
              </div>
            </Grid>
          </Grid>
        ))}
    </Shell>
  );
};

export default Confirmation;
