import { useState } from "react";
import classes from "./index.module.css";
import getTextConstants from "constants/copy";
import { Button, Checkbox, Grid, Typography } from "@mui/material";
import {
  isDateValid,
  isPhoneValid,
  isEmailValid,
  isStringValid,
  isValueSelected,
  isChecked,
  checkPassword,
} from "utilities";
import { useParams } from "react-router-dom";
import { DateTime } from "luxon";
import eyeOpen from "../../assets/img/vector (6).png";
import eyeClosed from "../../assets/img/vector (7).png";
import DashImage from "../../assets/img/dashIcon.svg";
import GreenTick from "../../assets/img/GreenTick.svg";
import RedCross from "../../assets/img/RedCross.svg";
import { SPONSOR_ACHQ } from "constants/app";

const validations = {
  email: {
    validation: isEmailValid,
    message: "This email is not valid ",
  },
  date: {
    validation: isDateValid,
    message: "Either date is invalid or your age is less than 18",
  },
  string: {
    validation: isStringValid,
    message: "This is a required field.",
  },
  // isBoldDependentFirstName: {
  //   validation: isStringValid,
  //   message: "Missing Dependent First Name",
  // },
  // isBoldDependentLastName: {
  //   validation: isStringValid,
  //   message: "Missing Dependent Last Name",
  // },
  // isBoldEmployeeFirstName: {
  //   validation: isStringValid,
  //   message: "Missing Employee Last Name",
  // },
  // isBoldEmployeeLastName: {
  //   validation: isStringValid,
  //   message: "Missing Employee Last Name",
  // },
  phone: {
    validation: isPhoneValid,
    message: "A valid phone number is required",
  },
  password: {
    validation: checkPassword,
    message: "This is a required field.",
  },
  selected: {
    validation: isValueSelected,
    message: "This is a required field.",
  },
  checked: {
    validation: isChecked,
    message: "This is a required field.",
  },
  atrial: {
    validation: isChecked,
    message: "You must have Atrial Fibrillation to enroll in KardiaComplete.",
  },
  firstName: {
    validation: true,
    message: "First Name does not match",
  },
};

const isFormValid = (form) => {
  return (
    Array.from(form.getElementsByTagName("input")).every(
      (input) => input.dataset.valid === "true"
    ) &&
    Array.from(form.getElementsByTagName("select")).every(
      (input) => input.dataset.valid === "true"
    )
  );
};

const apiDateFormat = "yyyy-MM-dd"; // date format for api payload
const getFormValues = (form) => {
  const inputs = Array.from(form.getElementsByTagName("input"));
  const reducer = (acc, input) => {
    // Skip unchecked radio buttons
    if(input.type=='radio' && !input.checked) return acc;

    acc[input.name] =
      input.type === "checkbox"
        ? input.checked
        : input.name === "dateOfBirth" || input.name === "employeeDateOfBirth"
        ? DateTime.fromJSDate(new Date(input.value)).toFormat(apiDateFormat)
        : input.value;
    return acc;
  };
  return inputs.reduce(reducer, {});
};

const getValidator = (validationTypes) => {
  const types = validationTypes.slice();
  return (e) => {
    const validators = types.map((type) =>
      type instanceof Object ? type : validations[type]
    );
    return validators?.map((v) => (v?.validation(e) ? null : v?.message));
  };
};

const validateField = (el, validation) => {
  let { value } = el;
  const validator = getValidator(validation);
  if (el.type === "checkbox") value = el.checked;
  return validator(value);
};

const Input = (props) => {
  const id = `${props.label?.replace(/\s/g, "")}`;
  const [valid, updateValidity] = useState(!Boolean(props.validation));
  const [validation] = useState(props.validation);
  const [showValidity, updateShowValidity] = useState(false);
  const [messages, updateMessages] = useState([]);
  const [mutated, updateMutated] = useState(false);
  let sponsor = useParams().sponsor.toUpperCase();
  const handleChange = (e) => {
    if (validation) {
      const validityResult = validateField(e.target, validation);
      const isValid = !validityResult.some((e) => e);
      updateMessages(validityResult);
      updateValidity(isValid);
      updateMutated(true);
      e.target.dataset.valid = isValid;
    }
    props.onChange(e);
  };

  const handleBlur = () => updateShowValidity(mutated);

  let input;
  switch (props.type) {
    case "email":
      input = (
        <label
          key={props.index}
          htmlFor={id}
          className={`${classes["email-field-label"]} ${
            (showValidity && !valid) || props.credentialValidation ||  props.errormessage
              ? classes.invalid
              : ""
          }`}
        >
          <div>
            <div>
              {props.label}
              {props.validation && (
                <span className={classes.asterikStyle}> *</span>
              )}
            </div>
            {props.recommendation === true && (
              <div className={classes.formSubText}>{props?.subText}</div>
            )}
          </div>
          {props.remove && (
            <Button
              variant="text"
              className={classes.remove}
              onClick={props.remove}
            >
              {getTextConstants("enrollment.form.removeDependent.provider")}
            </Button>
          )}
          {/* <br /> */}
          <input
            id={id}
            name={props.name}
            className={classes["text-field"]}
            value={props.value || ""}
            onChange={handleChange}
            placeholder={props.placeholder}
            data-validation={props.validation}
            data-valid={valid}
            onBlur={handleBlur}
          />
          <span className={classes.error}>
            {props?.credentialValidation
              ? props?.credentialValidation
              :props.errormessage || messages}
          </span>
        </label>
      );
      break;
    case "password":
      input = (
        <label
          key={props.index}
          htmlFor={id}
          className={`${classes["password-field-label"]} ${
            showValidity && !valid ? classes.invalidPass : ""
          }`}
        >
          <div>
            <div>
              {props.label}
              {props.validation && (
                <span className={classes.asterikStyle}> *</span>
              )}
            </div>
          </div>
          {/* <br /> */}
          <div className={`${classes["passwordEye"]}`}>
            <input
              id={id}
              name={props.name}
              className={`${classes["text-field"]} ${classes["passwordField"]}`}
              value={props.value || ""}
              onChange={handleChange}
              placeholder={props.placeholder}
              data-validation={props.validation}
              data-valid={valid}
              onBlur={handleBlur}
              type={`${props.passwordOpen ? "text" : "password"}`}
            />
            <span
              className={classes.eyeStyle}
              onClick={() => props.setPasswordOpen(!props.passwordOpen)}
            >
              <img
                style={{ height: 15, width: 19 }}
                src={`${props.passwordOpen ? eyeOpen : eyeClosed}`}
              ></img>
            </span>
          </div>
          {!props.noPassword && (
            <div style={{ marginTop: 13 }}>
              {props.name !== "confirmPassword" && (
                <div className={classes.passValStyle}>
                  Your password must include:
                </div>
              )}
              <div className={classes.passValStyle} style={{ marginTop: 10 }}>
                {props.name !== "confirmPassword" && (
                  <>
                    <div className={classes.charStyle}>
                      <img
                        src={
                          props.passwordValidation === null
                            ? DashImage
                            : props.passwordValidation?.characterVal === true
                            ? GreenTick
                            : RedCross
                        }
                        style={{
                          marginRight: 15,
                          height: props.passwordValidation !== null && 20,
                          width: props.passwordValidation !== null && 20,
                        }}
                      />
                      <span>8-20 characters(no spaces)</span>
                    </div>
                    <div className={classes.charStyle}>
                      <img
                        src={
                          props.passwordValidation === null
                            ? DashImage
                            : props.passwordValidation?.upperVal === true
                            ? GreenTick
                            : RedCross
                        }
                        style={{
                          marginRight: 15,
                          height: props.passwordValidation !== null && 20,
                          width: props.passwordValidation !== null && 20,
                        }}
                      />
                      <span>At least one upper case letter</span>
                    </div>
                    <div className={classes.charStyle}>
                      <img
                        src={
                          props.passwordValidation === null
                            ? DashImage
                            : props.passwordValidation?.lowerVal === true
                            ? GreenTick
                            : RedCross
                        }
                        style={{
                          marginRight: 15,
                          height: props.passwordValidation !== null && 20,
                          width: props.passwordValidation !== null && 20,
                        }}
                      />
                      <span>At least one lower case letter</span>
                    </div>
                    <div className={classes.charStyle}>
                      <img
                        src={
                          props.passwordValidation === null
                            ? DashImage
                            : props.passwordValidation?.numberVal === true
                            ? GreenTick
                            : RedCross
                        }
                        style={{
                          marginRight: 15,
                          height: props.passwordValidation !== null && 20,
                          width: props.passwordValidation !== null && 20,
                        }}
                      />
                      <span>At least one number</span>
                    </div>
                  </>
                )}
                {props.name === "confirmPassword" && (
                  <div className={classes.charStyle}>
                    <img
                      src={
                        props.passwordValidation === null
                          ? DashImage
                          : props.value === props.passwordValue
                          ? GreenTick
                          : RedCross
                      }
                      style={{
                        marginRight: 15,
                        height: props.passwordValidation !== null && 20,
                        width: props.passwordValidation !== null && 20,
                      }}
                    />
                    <span>Password and Confirm Password should match</span>
                  </div>
                )}
              </div>
            </div>
          )}
          {props.noPassword && (
            <span className={classes.error}>{messages}</span>
          )}
        </label>
      );
      break;
    case "noInput":
      input = (
        <label
          key={props.index}
          htmlFor={id}
          className={`${classes["password-field-label"]} ${
            showValidity && !valid ? classes.invalid : ""
          }`}
        >
          <div>
            <div>
              {props.label}
              {<span className={classes.asterikStyle}> *</span>}
            </div>
          </div>
          {/* <br /> */}
          <div className={classes.noInputText}>{props.textValue}</div>
        </label>
      );
      break;
    case "label-only":
      input = (
        <label
          key={props.index}
          htmlFor={id}
          className={`${classes["field-label"]} ${
            showValidity && !valid ? classes.invalid : ""
          }`}
        >
          {props.label}{" "}
          {props.label != "" && (
            <span className={classes.asterikStyle}> *</span>
          )}
          <div className={classes.formSubText}>{props?.subText}</div>
        </label>
      );
      break;
    case "select":
      input = (
        <label
          key={props.index}
          htmlFor={id}
          className={`${classes["field-label"]} ${
            showValidity && !valid ? classes.invalid : ""
          }`}
        >
          {props.label}{" "}
          {props.label != "" && (
            <span className={classes.asterikStyle}> *</span>
          )}
          <div className={classes.formSubText}>{props?.subText}</div>
          <select
            id={id}
            name={props.name}
            className={classes["select-field"]}
            value={props.value || ""}
            onChange={handleChange}
            data-validation={props.validation}
            data-valid={valid}
            onBlur={handleBlur}
          >
            {!props.value && (
              <option value="" disabled selected hidden>
                {props.placeholder}
              </option>
            )}
            {props.options.map((option) => (
              <option value={option.value} key={option.id}>
                {option.name}
              </option>
            ))}
          </select>
          <span className={classes.error}>{messages}</span>
        </label>
      );
      break;
    case "checkbox":
      input = (
        <label
          key={props.index}
          htmlFor={id}
          className={`${classes["field-label-checkbox"]} ${
            showValidity && !valid ? classes.invalid : ""
          }`}
        >
          <Grid container className={`${classes["checkbox-grid"]}`} 
            sx={
              {...(props.name === "consentMarketing" && {marginTop: '25px'})}
            }
          >
            <Grid item xs={1} style={{ maxWidth: 35 }} >
              <input
                type="checkbox"
                id={id}
                className={classes["checkbox-field"]}
                name={props.name}
                onBlur={handleBlur}
                onChange={handleChange}
                data-validation={props.validation}
                data-valid={valid}
              />
            </Grid>
            <Grid item xs={10}>
              {props.name === "consentTosPp" && (
                <Typography
                  variant="body1"
                  style={{ fontFamily: "'DM Sans', sans-serif", fontSize: 16 }}
                >
                  I agree to the{" "}
                  <a
                    href="http://kardia.com/kardiacomplete/tos"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#174E8C", textUnderlineOffset: 2 }}
                  >
                    Terms of Service
                  </a>
                  {`${sponsor === SPONSOR_ACHQ ? ", " : " and "}`}
                  <a
                    href="http://www.kardia.com/privacy"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#174E8C", textUnderlineOffset: 2 }}
                  >
                    Privacy Policy{" "}
                  </a>
                  {sponsor === SPONSOR_ACHQ && (
                    <>
                      and{" "}
                      <a
                        href="https://docs.google.com/document/d/1wvyHCRUIMpCpQ_8cltOjVRKMH_XrWGHw/edit"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "#174E8C", textUnderlineOffset: 2 }}
                      >
                        Employee Consent
                      </a>
                    </>
                  )}
                  {props.validation && (
                    <span className={classes.asterikStyle}>*</span>
                  )}
                </Typography>
              )}{" "}
              {props.name === "consentMarketing" && (
                <Typography
                  variant="body1"
                  style={{ fontFamily: "'DM Sans', sans-serif", fontSize: 16 }}
                >
                  I consent to receive marketing communications from AliveCor
                  about products and services. I can opt out at anytime, as
                  described in the Privacy Policy.
                  {/* <span
                    style={{
                      fontFamily: "Helvetica Neue",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "14px",
                      color: "#CC3D3F",
                    }}
                  >
                    (optional)
                  </span> */}
                </Typography>
              )}
              {(props.name === "atrialFibrillation" ||
                props.name === "hypertensionBox" ||
                props.name === "arrhythmiaDiagnosis") && (
                <Typography
                  variant="body1"
                  style={{
                    fontFamily: "'DM Sans', sans-serif",
                    fontSize: 16,
                  }}
                >
                  {props.label}
                  {props.validation && (
                    <span className={classes.asterikStyle}> *</span>
                  )}
                </Typography>
              )}
              {props.validation && (
                <span className={classes.error}>{messages}</span>
              )}
            </Grid>
          </Grid>
        </label>
      );
      break;
    case "radio":
      input = (
        <label
          key={props.index}
          htmlFor={id}
          className={`${classes["field-label-radio"]} ${showValidity && !valid ? classes.invalid : ""
            }`}
        >
          <Grid container className={`${classes["checkbox-grid"]}`}>
            <Grid item xs={1} style={{ maxWidth: 35 }}>
              <input
                type="radio"
                id={id}
                className={classes["checkbox-field"]}
                name={props.name}
                value={props.value}
                onBlur={handleBlur}
                onChange={handleChange}
                data-validation={props.validation}
                data-valid={valid}
                checked={props.checked}
              />
            </Grid>
            <Grid item xs={10}>
              <Typography
                variant="body1"
                style={{ fontFamily: "'DM Sans', sans-serif", fontSize: 16 }}
              >
                  {props.label}
              </Typography>
              {props.validation && (
                <span className={classes.error}>{messages}</span>
              )}
            </Grid>
          </Grid>
        </label>
      );
      break;
    default:
      input = (
        <label
          key={props.key}
          htmlFor={id}
          className={`${classes["field-label"]} ${
            (showValidity && !valid) || props.credentialValidation
              ? classes.invalid
              : ""
          }`}
        >
          {props.label}
          {props.validation && <span className={classes.asterikStyle}> *</span>}
          <div className={classes.formSubText}>{props?.subText}</div>
          <input
            id={id}
            name={props.name}
            className={classes["text-field"]}
            value={props.value || ""}
            onChange={handleChange}
            placeholder={props.placeholder}
            data-validation={props.validation}
            data-valid={valid}
            onBlur={handleBlur}
          />
          <span className={classes.error}>
            {props?.credentialValidation
              ? props?.credentialValidation
              : messages}
          </span>
        </label>
      );
      break;
  }
  return input;
};

/* const EmailInput = props => {
  const id = `${props.label.replace(/\s/g, '')}`;
  const [valid, updateValidity] = useState(!Boolean(props.validation));
  const [showValidity, updateShowValidity] = useState(false);
  const [messages, updateMessages] = useState([]);
  const [mutated, updateMutated] = useState(false);
  const handleChange = e => {
    const validityResult = validateField(e.target);
    const isValid = !validityResult.some(e => e);
    updateMessages(validityResult);
    updateValidity(isValid);
    updateMutated(true);
    e.target.dataset.valid = isValid;
    props.onChange(e);
  }
  const handleBlur = () => updateShowValidity(mutated);
  return (
    <label key={props.index} htmlFor={id} className={`${classes['email-field-label']} ${showValidity && !valid ?classes.invalid:''}`}>
      {props.label}
      {props.remove && <Button variant="text" className={classes.remove} onClick={props.remove}>{getTextConstants('enrollment.form.removeDependent.provider')}</Button>}
      <input
        id={id}
        name={props.name}
        className={classes['text-field']}
        value={props.value || ''}
        onChange={handleChange}
        placeholder={props.placeholder}
        data-validation={props.validation}
        data-valid={valid}
        onBlur={handleBlur} />
      <span className={classes.error}>{ messages }</span>
    </label>
  )
}

const SelectInput = props => {
  const id = `${props.label.replace(/\s/g, '')}`;
  const [valid, updateValidity] = useState(!Boolean(props.validation));
  const [showValidity, updateShowValidity] = useState(false);
  const [messages, updateMessages] = useState([]);
  const [mutated, updateMutated] = useState(false);
  const handleChange = e => {
    const validityResult = validateField(e.target);
    const isValid = !validityResult.some(e => e);
    updateMessages(validityResult);
    updateValidity(isValid);
    updateMutated(true);
    e.target.dataset.valid = isValid;
    props.onChange(e);
  }
  const handleBlur = () => updateShowValidity(mutated);
  return (
    <label key={props.index} htmlFor={id} className={`${classes['field-label']} ${showValidity && !valid ?classes.invalid:''}`}>
      {props.label}
      <select
        id={id}
        name={props.name}
        className={classes['select-field']}
        value={props.value || ''}
        onChange={handleChange}
        placeholder={props.placeholder}
        data-validation={props.validation}
        data-valid={valid}
        onBlur={handleBlur}>
        <option value="" key={'empty value'}>{props.defaultOptionName}</option>
        {props.options.map(option => <option value={option.id} key={option.id}>{option.name}</option>)}
      </select>
      <span className={classes.error}>{ messages }</span>
    </label>
  )
}

const CheckboxInput = props => {
  const id = `${props.label.replace(/\s/g, '')}`;
  const [valid, updateValidity] = useState(!Boolean(props.validation));
  const [showValidity, updateShowValidity] = useState(false);
  const [messages, updateMessages] = useState([]);
  const [mutated, updateMutated] = useState(false);
  const handleChange = e => {
    // const isValid = props.validation ? validateField(e.target) : true;
    const validityResult = validateField(e.target);
    const isValid = !validityResult.some(e => e);
    updateMessages(validityResult);
    updateValidity(isValid);
    updateMutated(true);
    e.target.dataset.valid = isValid;
    props.onChange(e);
  }
  const handleBlur = () => updateShowValidity(mutated);
  return (
    <label key={props.index} htmlFor={id} className={`${classes['field-label']} ${showValidity && !valid ?classes.invalid:''}`}>
      <input
        type="checkbox"
        id={id}
        name={props.name}
        onBlur={handleBlur}
        onChange={handleChange}
        data-validation={props.validation}
        data-valid={valid} />
      {props.label}
      { props.validation && <span className={classes.error}>{ messages }</span> }
    </label>
  )
} */

const DateInput = (props) => {
  return <div>date input</div>;
};

export {
  isFormValid,
  Input,
  DateInput,
  getFormValues /* TextInput, EmailInput, SelectInput, CheckboxInput, DateInput */,
};
