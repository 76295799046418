import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import QrCode from "../../assets/img/QrCode.png";
import classes from "./index.module.css";
import { ReactComponent as Round } from "../../assets/img/round.svg";
import { ReactComponent as AppStore } from "../../assets/img/appStore.svg";
import { ReactComponent as GooglePlay } from "../../assets/img/googlePlay.svg";
import { APP_STORE_LINK, PLAY_STORE_LINK } from "constants/enrollment";

const ConfirmationAndQR = () => {
  const appsStoreNavigation = () => {
    //re-direct to app store based on device
    const isAndroid = navigator.userAgent.match(/Android/i);
    const link = isAndroid ? PLAY_STORE_LINK : APP_STORE_LINK;
    window.location.assign(link);
  };

  //Steps for the user to follow after account creation
  const steps = [
    "You will receive an email confirming when your devices have shipped.",
    "Download the Kardia App and log in with your account information.",
    "Start exploring what your KardiaComplete membership has to offer.",
  ];

  //Render steps
  const renderStep = (step, index) => (
    <Box key={index} className={classes.nextStepsContainer}>
      <Round />
      <Typography variant="h6" className={classes.nextSteps}>
        {step}
      </Typography>
    </Box>
  );

  return (
    <Grid container>
      {/* QR Code Section */}
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          textAlign: "center",
          padding: { xs: "0px 20px", md: "0px 40px" },
        }}
      >
        <img
          alt="QR code to download the KardiaComplete app"
          src={QrCode}
          className={classes.qrCode}
        />
        <Typography variant="h1" className={classes.accountCreated}>
          Your account has successfully been created!
        </Typography>
        <Typography className={classes.emailNote}>
          You will receive a confirmation email from support@alivecor.com
          confirming when your devices have shipped. If you have questions or
          concerns, please feel free to reach out to our customer care team.
        </Typography>
      </Grid>

      {/* Next Steps Section */}
      <Grid item xs={12} md={6}>
        <Box>
          <Typography variant="h4" className={classes.nextStepsHeading}>
            Next Steps:
          </Typography>
          {steps.map(renderStep)}
        </Box>

        {/* App Store Section */}
        <Box className={classes.store}>
          <AppStore onClick={() => appsStoreNavigation()} role="button" />
          <GooglePlay onClick={() => appsStoreNavigation()} role="button" />
        </Box>
      </Grid>
    </Grid>
  );
};

export default ConfirmationAndQR;
