import React, { useCallback } from "react";
import { Box, Typography, Stepper, Step, StepLabel } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useParams } from "react-router-dom";
import styles from "./index.module.css";
import { BOLD, SPONSOR_HP, SUBJECT_PROVIDER } from "constants/app";
import { useSelector } from "react-redux";
import {
  EXISTING_USER_STEPS,
  EXISTING_USER_STEPS_PROFILE,
  EXISTING_USER_STEPS_SHIPPING,
  USER_PROGRESS_STEPS,
  stepper_text,
} from "constants/enrollment";

const useStyles = makeStyles({
  root: {
    "& .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed": {
      color: "#174E8C",
    },
    "& .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active": {
      color: "#174E8C",
    },
    "& .css-vnkopk-MuiStepLabel-iconContainer": {
      background: "white",
      borderRadius: "50%",
      padding: "5px",
    },
    "& .css-4ff9q7.Mui-active": {
      color: "#174E8C !important",
    },
    "& .css-a5nipc.MuiStepLabel-alternativeLabel": {
      background: "white !important",
      borderRadius: "50% !important",
      padding: "5px !important",
    },
    "& .css-4ff9q7.Mui-completed": {
      color: "#174E8C !important",
    },
    "& .css-zpcwqm-MuiStepConnector-root": {
      top: "15px !important",
    },
  },
});

export const StepperMessage = (props) => {
  const classes = useStyles();

  const userProgress = useSelector((state) => state.user.userProgress);
  let sponsor = useParams().sponsor.toUpperCase();
  let subject = useParams().subject;

  // ASP-281
  const steps = props?.login
    ? [stepper_text.LOG_IN]
    : [
        stepper_text.ENROLMENT,
        stepper_text.SHIPPING,
        stepper_text.CONFIRMATION,
      ];
  const enrollSteps = [
    "Welcome!",
    subject === SUBJECT_PROVIDER ? "Add Dependent" : "Employee Information",
  ];

  const getSteps = () => {
    if (props.noMessage === true || subject === SUBJECT_PROVIDER)
      return enrollSteps;
    else if (userProgress === USER_PROGRESS_STEPS.ACCOUNT_CREATION) {
      return EXISTING_USER_STEPS_PROFILE;
    } else if (userProgress === USER_PROGRESS_STEPS.SETTING_PROFILE) {
      return EXISTING_USER_STEPS_SHIPPING;
    } else return steps;
  };

  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <Stepper
          activeStep={props.activeStep}
          alternativeLabel
          className={`${styles.stepperStyle} ${classes.root}`}
        >
          {getSteps().map((label) => (
            <Step key={label}>
              <StepLabel>
                <Typography
                  variant="subtitle1"
                  style={{
                    fontSize: 18,
                    fontFamily: "'DM Sans', sans-serif",
                    fontWeight: 600,
                    lineHeight: "inherit",
                    color: "black",
                  }}
                >
                  {label}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      {/* )} */}
      {props.noMessage !== true && (
        <div
          className={styles.cvsTitleEnrolltext}
          style={{
            margin:
              props.marginBottom === true ? "4vh auto auto auto" : "4vh auto",
          }}
        >
          <Typography variant="h4" className={styles.titleMessage}>
            {props.title}
          </Typography>
          <Typography variant="h4" className={styles.titleNote}>
            {props.titleNote}
          </Typography>
          <Typography variant="subtitle1" className={styles.subheadline}>
            {props.subtitle}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default StepperMessage;
