import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Grid, Typography, Box, Stepper, Step, StepLabel } from "@mui/material";
import Shell from "../shell";
import { OutlinedButton, SolidButton } from "../buttons";
import classes from "./index.module.css";
import { makeStyles } from "@mui/styles";
import {
  SUBJECT_EMPLOYEE,
  SUBJECT_DEPENDENT,
  SPONSOR_CVS,
  BOLD,
  SUBJECT_PROVIDER,
  SPONSOR_HP,
  SPONSOR_AC,
  SPONSOR_NITERRA,
  SPONSOR_ACHQ,
} from "../../constants/app";
import StepperMessage from "components/stepperMessage";
import { getSponsorRules } from "data";
import NotFoundPage from "components/NotFoundPage";
const steps = ["Welcome!", "Employee Information"];

const useStyles = makeStyles({
  root: {
    "& .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active": {
      color: "#174E8C !important",
    },
    "& .css-vnkopk-MuiStepLabel-iconContainer": {
      background: "white",
      borderRadius: "50%",
      padding: "5px",
    },
    "& .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed": {
      color: "#174E8C !important",
    },
    "& .css-4ff9q7.Mui-active": {
      color: "#174E8C !important",
    },
    "& .css-a5nipc.MuiStepLabel-alternativeLabel": {
      background: "white !important",
      borderRadius: "50% !important",
      padding: "5px !important",
    },
    "& .css-zpcwqm-MuiStepConnector-root": {
      top: "15px !important",
    },
    "& .css-i0u8l7-MuiTypography-root": {
      color: "black !important",
    },
  },
});
const Enrollment = () => {
  const styles = useStyles();
  const params = useParams();
  const navigate = useNavigate();
  let sponsor = useParams().sponsor.toUpperCase();
  const [rules, setRules] = useState();
  let subject = useParams().subject;

  useEffect(() => {
    if ([SPONSOR_CVS, SPONSOR_NITERRA, SPONSOR_HP].includes(sponsor))
      navigate(`/enroll/${sponsor}/${SUBJECT_EMPLOYEE}`);
    async function getRules() {
      let data = await getSponsorRules(sponsor);
      let resp = await data.json();
      setRules(resp);
    }
    getRules();
  }, []);

  if (rules?.errors?.length > 0) {
    return (
      <div>
        <NotFoundPage />
      </div>
    );
  } else {
    return (
      <Shell>
        <Grid container className={classes.container}>
          <Grid item sm={12} md={8} lg={8} xl={8}>
            <StepperMessage noMessage={true} activeStep={0} />
            <Grid className={classes["enrollAlignTexts"]}>
              <div className={classes["enrollTextFirst"]}>
                <Typography
                  variant="h2"
                  style={{
                    color: "#182C3D",
                    marginBottom: "8px",
                    fontSize: 36,
                    fontFamily: "'DM Sans', sans-serif",
                  }}
                >
                  Welcome to KardiaComplete!
                </Typography>
                <Typography
                  variant="h6"
                  style={{
                    fontFamily: "'DM Sans', sans-serif",
                    fontSize: 19,
                    fontWeight: 600,
                  }}
                >
                  Enrollment is fast and simple, and gives you immediate access
                  to KardiaComplete at no cost to you.
                </Typography>
              </div>
              {rules?.eligibleUserConstraint === false ? (
                <Typography
                  variant="h6"
                  style={{
                    fontFamily: "'DM Sans', sans-serif",
                    fontSize: 20,
                    fontWeight: 700,
                  }}
                >
                  Please select the option that applies to you:
                </Typography>
              ) : (
                <div>
                  <Typography
                    variant="h4"
                    style={{
                      marginBottom: "5px",
                      fontFamily: "'DM Sans', sans-serif",
                      fontSize: 22,
                    }}
                  >
                    I'm enrolling as a(n):
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    style={{
                      fontFamily: "'DM Sans', sans-serif",
                      fontSize: 17,
                      fontWeight: 400,
                    }}
                  >
                    Select Employee if you are the primary member of your
                    insurance plan. Select Dependent if you are listed as a
                    dependent on the primary insurance holder's plan.
                  </Typography>
                </div>
              )}
              <Box className={classes.button_alignment}>
                <SolidButton
                  sponsor={params.sponsor}
                  text={`${
                    rules?.eligibleUserConstraint === false
                      ? "ENROLL MYSELF"
                      : "EMPLOYEE"
                  }`}
                  link={`${SUBJECT_EMPLOYEE}`}
                  size={"13vw"}
                />
                <OutlinedButton
                  sponsor={params.sponsor}
                  text={`${
                    rules?.eligibleUserConstraint === false
                      ? "ADD a DEPENDENT"
                      : "DEPENDENT"
                  }`}
                  link={
                    rules?.eligibleUserConstraint === false
                      ? SUBJECT_PROVIDER
                      : SUBJECT_DEPENDENT
                  }
                  size={"13vw"}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Shell>
    );
  }
};
export default Enrollment;
