import { userInfoAction } from "store/actions";
import { ENDPOINT as BASE, POINT, PATHS, GET_CONFIG, POST_CONFIG, USERS, KCC } from "./config";
import { SPONSOR_HP } from "constants/app";

const registrationPath = `${BASE}${PATHS.registration}`;

export const getRegistration = async (id) => {
  const config = { ...GET_CONFIG };
  const resp = await fetch(`${registrationPath}${id}`, config);
  return await resp.json();
};

export const updateRegistration = async (registration) => {
  const config = { ...POST_CONFIG };
  let queryParameter='';
  if(registration.sponsorSymbol==SPONSOR_HP){
    queryParameter = `?hpUserType=${registration.employeeOrDependent}`
  }
  delete registration.employeeOrDependent
  config.body = JSON.stringify(registration);
  const resp = await fetch(`${registrationPath}${queryParameter}`, config);
  return await resp.json();
};

const dependentEnrolPath = `${BASE}${PATHS.enrolDependent}`;
export const updateDependentEnrolment = async (registration) => {
  //calling api with payload when dependents enroll themselves.
  const config = { ...POST_CONFIG };

  config.body = JSON.stringify(registration);
  const resp = await fetch(`${registrationPath}?mode=dependent`, config);
  return resp;
};

export const loginAPI = async (loginValues) => {
  //calling api with payload when dependents enroll themselves.
  const config = { ...POST_CONFIG };
  config.body = JSON.stringify(loginValues);
  const resp = await fetch(`${process.env.REACT_APP_KCC_KARDIA_ENDPOINT}${PATHS.authorization}`, config);
  return resp;
};


const dependentInvitePath = `${KCC}`;
export const updateDependentInvite = async (registration) => {
  //calling api with payload when employee invites dependents
  let a = [];
  const d = Object.entries(registration).reduce((acc, cur) => {
    let [key, value] = cur;
    if (key.includes("dependentEmail")) {
      //converting to array format ie. dependentEmails: [""]
      // return {...acc, 'dependentEmails': [...(acc[value] || ''), value]}
      a.push(value);
      return { ...acc, dependentEmails: a };
    } else {
      return { ...acc, [key]: value };
    }
  }, {});
  const config = { ...POST_CONFIG };
  config.body = JSON.stringify(d);
  const resp = await fetch(`${process.env.REACT_APP_KCC_KARDIA_ENDPOINT}${PATHS.inviteDependent}`, config);
  return await resp.json();
};


export const profileAPI = async (profileValues, data) => {
  let config = { ...POST_CONFIG };
  config.body = JSON.stringify(profileValues);
  config['headers']['Authorization'] = data?.auth_token;
  const resp = await fetch(`${process.env.REACT_APP_KCC_KARDIA_ENDPOINT}${USERS}/${data?.public_id}`, config);
  return resp;
}

export const shippingAPI = async (shippingValues, data) => {
  let config = { ...POST_CONFIG };
  config.body = JSON.stringify(shippingValues);
  config['headers']['Authorization'] = data?.auth_token;
  const resp = await fetch(`${process.env.REACT_APP_KARDIA_ENDPOINT}${POINT}/verify_address`, config);
  return resp;
}

export const verifyingShipping = async (shippingValues, data) => {
  let config = { ...POST_CONFIG };
  config.body = JSON.stringify(shippingValues);
  config['headers']['Authorization'] = data?.auth_token;
  const resp = await fetch(`${process.env.REACT_APP_KCC_KARDIA_ENDPOINT}${KCC}/addShippingDetails`, config);
  return resp;
}

export const userProfile = async (url) => {
  let config = { ...GET_CONFIG };
  config['headers']['Authorization'] = url;
  const resp = await fetch(`${process.env.REACT_APP_KCC_KARDIA_ENDPOINT}${KCC}/userprogress`, config);
  return resp;
}

export const verifyEmail = async (email) => {
  let config = { ...POST_CONFIG };
  config.body = JSON.stringify(email);
  const resp = await fetch(`${process.env.REACT_APP_KCC_KARDIA_ENDPOINT}${KCC}/validate`, config);
  return resp;
}

export const getSponsorRules = async(sponsor) => {
  let config = { ...GET_CONFIG };
  // config['headers']['Authorization'] = url;
  try{
  const resp = await fetch(`${process.env.REACT_APP_KCC_KARDIA_ENDPOINT}${KCC}/${sponsor}/rules`, config);
  return resp;
  }catch(err){
    console.err(err)
  }
}